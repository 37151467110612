import { Action } from '@/types/action';

export function requestProcess(): Action {
  return {
    type: 'REQUEST_PROCESS',
  };
}

export function completeProcess(): Action {
  return {
    type: 'COMPLETE_PROCESS',
  };
}
