/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'clsx';
import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import * as masterActions from '@/actions/master';
import Layout from '@/components/layout';
import SEO from '@/components/seo';

type Props = {
  requestProcess: void;
  completeProcess: void;
  isProcessing: boolean;
  data: {
    wordpressPage: {
      id: string;
      content: string;
      title: string;
    };
  };
  wpModel: any;
  classes: {
    root: string;
    componentWrapper: string;
    body: string;
    requiredLabel: string;
    birthday: string;
    itemWidth: string;
    selectLabel: string;
    gender: string;
    bootstrapRoot: string;
    bootstrapInput: string;
    bootstrapFormLabel: string;
    button: string;
    privacy: string;
    title: string;
    privacyContainer: string;
  };
};

type State = {
  checked: boolean;
};

class ContactPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  _handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.checked,
    } as Pick<State, keyof State>);
  };

  render() {
    const { classes } = this.props;

    const subject = [
      '事業に関するお問合せ：普及事業',
      '事業に関するお問合せ：ソーシャルアクション事業',
      '事業に関するお問合せ：キャリアビルディング事業',
      '相談窓口に関するお問合せ',
      '企業研修・講演に関するお問合せ',
      '取材のお申し込み',
      'その他',
    ];

    return (
      <Layout className="pt20">
        <SEO title="お問い合わせ" />
        <div className={classes.root}>
          <div className={classes.componentWrapper}>
            <section id="privacy">
              <div className="title-wrapper pt20">
                <h1 className={classNames('title', classes.title)}>
                  お問い合わせ
                </h1>
              </div>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <FormLabel component="legend" className={classes.requiredLabel}>
                  * 必須項目
                </FormLabel>

                <div className="mt20 mb20">
                  <FormLabel component="legend" className={classes.selectLabel}>
                    お問い合わせ項目 *
                  </FormLabel>
                  <div>
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel htmlFor="subject" id="subject-label">
                        選択してください
                      </InputLabel>
                      <Select
                        labelId="subject-label"
                        native
                        inputProps={{
                          name: 'subject',
                          id: 'subject',
                        }}
                        label="選択してください"
                      >
                        <option value="" />
                        {(() => {
                          return subject.map((sub: string, i: number) => {
                            return (
                              <option key={`subject-${i}`} value={sub}>
                                {sub}
                              </option>
                            );
                          });
                        })()}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <FormControl fullWidth={true}>
                  <TextField
                    label="会社名または団体名"
                    id="company"
                    name="company"
                    margin="normal"
                    required
                    className="mt20 mb20"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="お名前"
                    id="name"
                    name="name"
                    margin="normal"
                    required
                    className="mt20 mb20"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="メールアドレス"
                    id="email"
                    type="email"
                    name="email"
                    //pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    margin="normal"
                    required
                    className="mt22 mb20"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="電話番号"
                    id="tel"
                    name="tel"
                    margin="normal"
                    className="mt20 mb20"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth={true} className="mt20 mb20">
                  <div className={classNames('mb20', 'sawarabi-gothic')}>
                    お問い合わせ内容を400字以内で入力してください
                  </div>
                  <TextField
                    multiline
                    rows={15}
                    id="message"
                    name="message"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.bootstrapFormLabel,
                    }}
                  />
                </FormControl>
                <div
                  className={classNames(
                    classes.privacyContainer,
                    'mt20',
                    'mb40'
                  )}
                >
                  <div>
                    <a
                      className={classNames(classes.privacy, 'sawarabi-gothic')}
                      href="/privacy/"
                      target="_blank"
                    >
                      プライバシーポリシー
                    </a>
                    にご同意の上、「送信」してください。
                  </div>
                  <div className={classNames('mt20', 'sawarabi-gothic')}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checked}
                          onChange={this._handleChange('checked')}
                        />
                      }
                      label="プライバシーポリシーに同意"
                    />
                  </div>
                </div>
                <div className="mt20">
                  <Button
                    disabled={!this.state.checked}
                    className={classNames(classes.button, 'sawarabi-gothic')}
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    type="submit"
                  >
                    送信
                  </Button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: '0 auto',
    maxWidth: 880,
    padding: '40px 80px',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px',
    },
  },
  body: {
    maxWidth: 834,
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: 1.6,
    color: '#808080',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  requiredLabel: {
    textAlign: 'right',
    width: '100%',
    marginBottom: 20,
    color: 'red',
  },
  birthday: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  itemWidth: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectLabel: {
    textAlign: 'left',
    marginTop: 14,
    marginBottom: 14,
    fontSize: 14,
  },
  gender: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Sawarabi Gothic',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  button: {
    fontSize: 26,
    fontWeight: 'bold',
    backgroundColor: '#c12f2f',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  privacy: {
    fontSize: 20,
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  title: {
    fontFamily: 'Sawarabi Gothic',
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px !important',
    },
  },
  privacyContainer: {
    textAlign: 'center',
  },
});

export const pageQuery = graphql`
  {
    wordpressPage(id: { eq: "43396804-61f5-5588-8438-4f91e0da836e" }) {
      id
      content
      title
    }
  }
`;

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master;

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  };
}

const connector = connect(mapStateToProps, { ...masterActions });

export default compose(pure, withStyles(styles), connector)(ContactPage);
